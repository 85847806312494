'use strict';

(function() {
  function ContactResource($resource) {
    return $resource('api/v1/contacts/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      update: {
        method: 'PUT'
      },
      checkImportStatus: {
        method: 'POST',
        params: {
          id: 'import',
          controller: 'check-status'
        }
      },
      importContact: {
        method: 'POST',
        params: {
          id: 'import',
          controller: 'contacts'
        }
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('Contact', ContactResource);

})();
